@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-progress-light: #9fafca;
  --toastify-color-progress-dark: #9fafca;
  --toastify-color-progress-info: #9fafca;
  --toastify-color-progress-success: #9fafca;
  --toastify-color-progress-warning: #9fafca;
  --toastify-color-progress-error: #9fafca;
}

html {
  scroll-behavior: smooth;
}

:target {
  display: block;
  position: relative;
  top: -45px;
  visibility: hidden;
}
